@font-face {
font-family: '__proximaFont_5998a0';
src: url(/_next/static/media/1cd98a7af07c46ba.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__proximaFont_5998a0';
src: url(/_next/static/media/73109dc2897a4e16.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__proximaFont_5998a0';
src: url(/_next/static/media/c7b3076dc3c79818.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__proximaFont_5998a0';
src: url(/_next/static/media/cc9dcd7225d25151.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__proximaFont_5998a0';
src: url(/_next/static/media/c046e462013b4f57.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}.__className_5998a0 {font-family: '__proximaFont_5998a0'
}.__variable_5998a0 {--my-proxima: '__proximaFont_5998a0'
}

